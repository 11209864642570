<template>
  <div class="px-3 fixed-footer">
    <div :class="['py-2 panel-sum rounded-lg']">
      <div>
        <div class="d-flex">
          <div><span class="font-weight-bold">ราคารวม</span></div>
          <div class="ml-auto">
            <span class="font-weight-bold">{{
              orderSelected.total | numeral("0,0.00")
            }}</span>
          </div>
        </div>
        <div class="d-flex pl-2">
          <div><span class="text-small">ส่วนลด</span></div>
          <div class="ml-auto">
            <span class="text-small">{{
              orderSelected.discount | numeral("0,0.00")
            }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex">
          <div><span class="font-weight-bold">ค่าจัดส่ง</span></div>
          <div class="ml-auto">
            <span class="text-small">{{
              orderSelected.shippingCost | numeral("0,0.00")
            }}</span>
          </div>
        </div>
        <div class="d-flex pl-2">
          <div><span class="text-small">ค่าจัดส่ง</span></div>
          <div class="ml-auto">
            <span class="text-small">{{
              orderSelected.shippingCost | numeral("0,0.00")
            }}</span>
          </div>
        </div>
        <div class="d-flex pl-2">
          <div><span class="text-small">ส่วนลด</span></div>
          <div class="ml-auto">
            <span class="text-small">{{
              orderSelected.discountShipping | numeral("0,0.00")
            }}</span>
          </div>
        </div>
      </div>
      <div class="d-flex font-weight-bold">
        <div><span class="">ราคาสุทธิ</span></div>
        <div class="ml-auto">
          <span class="">{{
            orderSelected.grandTotal | numeral("0,0.00")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatOrderSum",
  props: {
    orderSelected: {
      required: false,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
// .panel-sum {
//   background-color: #f4cccc;
// }
</style>
