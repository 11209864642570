<template>
  <div>
    <ChatOrderProfileAddressForm
      v-if="isShowAddressShipping"
      ref="ChatOrderProfileAddressFormShipping"
      :addressInfo="addressInfoShipping"
      :userInfo="userInfo"
      title="ที่อยู่ในการจัดส่ง"
      class="pb-2"
      typeAddress="shipping"
      @handle-save-form="handleSaveForm"
      @handle-set-addres-info="val => (addressInfoShipping = val)"
      :isProfileInfo="isProfileInfo"
      :sameAddress="sameAddress"
      :isOrderSendered="isOrderSendered"
      :isRequestTaxInvoice="isRequestTaxInvoice"
      @set-change-same-address="val => (sameAddress = val)"
      @set-change-request-tax-invoice="val => (isRequestTaxInvoice = val)"
      @cancle-edit="
        () => {
          sameAddress = orderSelected.buyerInfo.sameAddress;
          isRequestTaxInvoice = orderSelected.buyerInfo.isRequestTaxInvoice;
        }
      "
    />
    <ChatOrderProfileAddressForm
      v-if="isShowAddressBilling"
      v-show="!sameAddress || isRequestTaxInvoice"
      ref="ChatOrderProfileAddressFormBilling"
      :addressInfo="addressInfoBilling"
      :userInfo="userInfo"
      title="ที่อยู่ในการส่งใบเสร็จ"
      class="pb-2"
      typeAddress="billing"
      @handle-save-form="handleSaveForm"
      @handle-set-addres-info="val => (addressInfoBilling = val)"
      :isProfileInfo="isProfileInfo"
      :sameAddress="sameAddress"
      :isRequestTaxInvoice="isRequestTaxInvoice"
      :isOrderSendered="isOrderSendered"
    />
  </div>
</template>

<script>
import ChatOrderProfileAddressForm from "./ChatOrderProfileAddressForm";
export default {
  name: "ChatOrderProfileAddressPanel",
  components: {
    ChatOrderProfileAddressForm
  },
  props: {
    userInfo: {
      required: false,
      type: Object
    },
    userDetail: {
      required: false,
      type: Object
    },
    isProfileInfo: {
      required: false,
      type: Boolean
    },
    isNoActionSave: {
      required: false,
      type: Boolean
    },
    isOrderSendered: {
      required: false,
      type: Boolean
    },
    orderSelected: {
      required: false,
      type: Object
    },
    userDetailForCheckout: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      isShowAddressShipping: false,
      isShowAddressBilling: false,
      addressInfoShipping: {
        zipcode: null
      },
      addressInfoBilling: {
        zipcode: null
      },
      sameAddress: false,
      isRequestTaxInvoice: false
    };
  },
  methods: {
    handleSetAddress: async function() {
      this.sameAddress = this.orderSelected.buyerInfo.sameAddress;
      this.isRequestTaxInvoice = this.orderSelected.buyerInfo.isRequestTax;
      this.addressInfoShipping = this.orderSelected.buyerInfo.shippingAddress;
      this.addressInfoBilling = this.orderSelected.buyerInfo.billingAddress;
    },
    getBuyerInfoBySocialReferenceId: async function() {
      await this.$axios
        .get(
          this.$baseUrl + "/api/user/address/" + this.userInfo.socialReferenceId
        )
        .then(response => {
          if (response.data.result == 1) {
            this.sameAddress = response.data.detail.sameAddress;
            this.isRequestTaxInvoice = response.data.detail.isRequestTax;
            this.addressInfoShipping = response.data.detail.billingAddress;
            this.addressInfoBilling = response.data.detail.shippingAddress;
          }
        });
    },
    setAddressFromUserDetail: async function() {
      this.sameAddress = this.userDetail.userProfile.sameAddress;
      this.isRequestTaxInvoice = this.userDetail.userProfile.isRequestTaxInvoice;
      await this.userDetail.userAddressList.forEach(ele => {
        if (ele.defaultShipping == true && ele.defaultBilling == false) {
          this.addressInfoShipping = ele;
        }
        if (ele.defaultShipping == false && ele.defaultBilling == true) {
          this.addressInfoBilling = ele;
        }
      });
    },
    handleSaveForm(val) {
      let body = {
        sameAddress: this.sameAddress,
        isRequestTax: this.isRequestTaxInvoice,
        shippingAddress: {},
        billingAddress: {}
      };
      if (this.isOrderSendered) {
        this.sameAddress = this.orderSelected.buyerInfo.sameAddress;
        this.isRequestTaxInvoice = this.orderSelected.buyerInfo.isRequestTax;
        this.addressInfoShipping = this.orderSelected.buyerInfo.billingAddress;
        this.addressInfoBilling = this.orderSelected.buyerInfo.shippingAddress;
      } else {
        this.userDetail.userAddressList.forEach(ele => {
          if (ele.defaultShipping == true && ele.defaultBilling == false) {
            body.shippingAddress = ele;
          }
          if (ele.defaultShipping == false && ele.defaultBilling == true) {
            body.billingAddress = ele;
          }
        });
      }
      if (val.typeAddress == "shipping") {
        body.shippingAddress = val.form;
      }
      if (val.typeAddress == "billing") {
        body.billingAddress = val.form;
      }
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .post(
          `${this.$baseUrl}/api/usersocial/${this.$route.query.brandID}/${this.userInfo.socialReferenceId}/address`,
          body
        )
        .then(response => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.result) {
            this.$emit("handle-get-user-info");
            if (val.typeAddress === "shipping") {
              this.$refs.ChatOrderProfileAddressFormShipping.hadleEdit(false);
              this.isShowAddressShipping = false;
              setTimeout(() => {
                this.isShowAddressShipping = true;
              }, 1000);
            }
            if (val.typeAddress === "billing") {
              this.$refs.ChatOrderProfileAddressFormBilling.hadleEdit(false);
              this.isShowAddressBilling = false;
              setTimeout(() => {
                this.isShowAddressBilling = true;
              }, 1000);
            }
            this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
          } else {
            alert(response.data.message);
          }
        });
    },
    getOrderAddress: async function() {
      let shipAdr = await this.$refs.ChatOrderProfileAddressFormShipping.handleReturnAddress();
      let billAdr = await this.$refs.ChatOrderProfileAddressFormBilling.handleReturnAddress();
      if (this.isRequestTaxInvoice && !billAdr.taxNo && !billAdr.tax) {
        if (!this.$refs.ChatOrderProfileAddressFormBilling.isEdit) {
          this.$refs.ChatOrderProfileAddressFormBilling.hadleEdit(true);
          this.$refs.ChatOrderProfileAddressFormBilling.handleReturnAddress();
        }
        return {};
      }
      if (this.sameAddress && !this.isRequestTaxInvoice) {
        billAdr.isTrue = true;
      }
      if (shipAdr.isTrue && billAdr.isTrue) {
        return {
          isHaveAddress: true,
          shippingAddress: shipAdr,
          billingAddress: billAdr,
          sameAddress: this.sameAddress,
          isRequestTax: this.isRequestTaxInvoice
        };
      } else {
        return {};
      }
    }
  },
  created: async function() {
    await this.handleSetAddress();
    this.isShowAddressShipping = true;
    this.isShowAddressBilling = true;
  },
  watch: {
    userDetail: function() {
      this.handleSetAddress();
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #eeeeee;
  border: none;
}
@media (max-width: 767.98px) {
}
</style>
