<template>
  <div>
    <ModalAlert
      ref="GlobalModalAlert"
      :text="$store.state.messageGlobalAlert"
      :textSecondLine="$store.state.messageGlobalAlertSecondLine"
      @hide="
        () => {
          $store.commit('setShowGlobalAlert', false);
          $store.commit('setMessageGlobalAlert', '');
          $store.commit('setMessageGlobalAlertSecondLine', '');
        }
      "
    />
    <ModalAlertError
      ref="GlobalModalAlertError"
      :text="$store.state.messageGlobalAlertError"
      :textSecondLine="$store.state.messageGlobalAlertErrorSecondLine"
      @hide="
        () => {
          $store.commit('setShowGlobalAlertError', false);
          $store.commit('setMessageGlobalAlertError', '');
          $store.commit('setMessageGlobalAlertErrorSecondLine', '');
        }
      "
    />
    <ModalAlertWarning
      ref="GlobalModalAlertWarning"
      :text="$store.state.messageGlobalAlertWarning"
      :textSecondLine="$store.state.messageGlobalAlertWarningSecondLine"
      @hide="
        () => {
          $store.commit('setShowGlobalAlertWarning', false);
          $store.commit('setMessageGlobalAlertWarning', '');
          $store.commit('setMessageGlobalAlertWarningSecondLine', '');
        }
      "
    />
  </div>
</template>
<script>
import ModalAlert from "./ModalAlert";
import ModalAlertError from "./ModalAlertError";
import ModalAlertWarning from "./ModalAlertWarning";
export default {
  name: "ModalPanelGloabal",
  components: {
    ModalAlert,
    ModalAlertError,
    ModalAlertWarning
  },
  data() {
    return {};
  },
  methods: {},
  watch: {
    "$store.state.isShowGlobalAlert": function() {
      if (this.$store.state.isShowGlobalAlert) {
        this.$refs.GlobalModalAlert.show();
      }
    },
    "$store.state.isShowGlobalAlertError": function() {
      if (this.$store.state.isShowGlobalAlertError) {
        this.$refs.GlobalModalAlertError.show();
      }
    },
    "$store.state.isShowGlobalAlertWarning": function() {
      if (this.$store.state.isShowGlobalAlertWarning) {
        this.$refs.GlobalModalAlertWarning.show();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
