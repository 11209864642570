<template>
  <div class="border bg-white px-3 py-2">
    <div class="text-overflow-wrap text-muted text-translated text-nextline">
      {{ text }}
    </div>
    <hr class="mb-2 mt-1" />
    <div v-if="isLoadingTranslate" class="d-flex w-100">
      <div
        class="spinner-border text-secondary spinner-border-sm"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="text-black text-nextline">
        {{ textTranslated }}
      </div>
      <div class="text-translated text-muted mt-2">TRANSLATED</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatBoxTranslateFlex",
  components: {},
  props: {
    text: {
      required: false,
      type: String
    },
    isLoadingTranslate: {
      required: false,
      type: Boolean
    },
    textTranslated: {
      required: false,
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.text-overflow-wrap {
  overflow-wrap: break-word;
}
.text-translated {
  font-size: 10px;
}
.text-nextline {
  white-space: pre-line;
}
@media (max-width: 767.98px) {
}
</style>
