<template>
  <span>
    <!-- {{ date }} -->
    {{ getHumanDate() }}
  </span>
</template>

<script>
export default {
  name: "ChatDateTime",
  props: {
    date: {
      required: false,
      type: String
    },
    isFullTime: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    getHumanDate: function() {
      if (this.isFullTime) {
        return this.$moment(this.date).format("DD MMM YYYY HH:mm");
      } else {
        if (
          this.$moment(this.date).format("DD") ===
          this.$moment(new Date()).format("DD")
        ) {
          return this.$moment(this.date).format("HH:mm");
        } else if (
          this.$moment(this.date).isSame(
            this.$moment().subtract(1, "day"),
            "day"
          )
        ) {
          return "เมื่อวาน, " + this.$moment(this.date).format("HH:mm");
        } else if (
          this.$moment(this.date).format("YYYY") ===
          this.$moment(new Date()).format("YYYY")
        ) {
          return this.$moment(this.date).format("DD MMM, HH:mm");
        } else {
          return this.$moment(this.date).format("DD MMM YYYY");
        }
      }
    }
  },
  data() {
    return {};
  },
  created: async function() {}
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
}
</style>
