import { render, staticRenderFns } from "./ChatOrderNote.vue?vue&type=template&id=84bb3f86&scoped=true&"
import script from "./ChatOrderNote.vue?vue&type=script&lang=js&"
export * from "./ChatOrderNote.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84bb3f86",
  null
  
)

export default component.exports