<template>
  <div class="panel">
    <div class="p-1 mb-0">
      <div class="panel-address">
        <b-row>
          <b-col md="6">
            <InputText
              v-model="form.address"
              textFloat="ที่อยู่"
              placeholder="ที่อยู่"
              type="text"
              name="address"
              size="lg"
              isRequired
              :isValidate="v.address.$error"
              :v="v.address"
              class="f-regular"
            />
          </b-col>

          <b-col md="6">
            <InputText
              v-model="form.road"
              textFloat="ถนน"
              placeholder="ถนน"
              type="text"
              name="road"
              size="lg"
              :v="v.road"
              class="f-regular"
            />
          </b-col>
          <b-col md="6">
            <InputText
              v-model="form.subdistrict"
              textFloat="ตำบล/แขวง"
              placeholder="ตำบล/แขวง"
              type="text"
              name="road"
              size="lg"
              :v="v.subdistrict"
              isRequired
              :isValidate="v.subdistrict.$error"
              class="f-regular"
            />
          </b-col>
          <b-col md="6">
            <InputText
              v-model="form.district"
              textFloat="อำเภอ"
              placeholder="อำเภอ"
              type="text"
              name="road"
              size="lg"
              :v="v.district"
              isRequired
              :isValidate="v.district.$error"
              class="f-regular"
            />
          </b-col>
          <b-col md="6">
            <InputText
              v-model="form.province"
              textFloat="จังหวัด"
              placeholder="จังหวัด"
              type="text"
              name="road"
              size="lg"
              :v="v.province"
              isRequired
              :isValidate="v.province.$error"
              class="f-regular"
            />
          </b-col>
          <b-col md="6">
            <InputText
              textFloat="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              type="tel"
              v-model="form.zipcode"
              name="zipcode"
              isRequired
              :v="v.zipcode"
              :isValidate="v.zipcode.$error"
              :maxlength="5"
              @onkeypress="isNumber"
              size="lg"
              ref="zipcode"
              class="f-regular"
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            @click="toggleEditAddress(2, 0, {})"
            class="btn-cancel-edit mr-2"
            >ยกเลิก</b-button
          >
          <b-button
            v-if="typeForm == 'Add'"
            @click="submitAddress(1)"
            class="bg-color-primary text-white"
            >เพิ่ม</b-button
          >
          <b-button
            v-else
            @click="submitAddress(2)"
            class="bg-color-primary text-white"
            >แก้ไข</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
export default {
  name: "ChatOrderProfileModalFormAddress",
  components: {
    InputText
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    toggleEditAddress: {
      required: true,
      type: Function
    },
    submitAddress: {
      required: true,
      type: Function
    },
    typeForm: {
      required: true,
      type: String
    }
  },
  data() {
    return {};
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  background-color: #eeeeee;
  border: none;
}
@media (max-width: 767.98px) {
}
</style>
