<template>
  <div>
    <div
      class="mx-1 mt-2"
      v-for="(free, index) in freeProducts"
      :key="index + 'freepromotion'"
    >
      <div class="pl-1" style="color:black">
        <b-row class="mt-1">
          <b-col class="pl-2">
            <label for="" class="m-0">
              โปรโมชั่น : {{ free.promotionName }}
            </label>
          </b-col>
        </b-row>

        <b-row
          class="px-1"
          v-for="(freeitem, index) in free.products"
          :key="index + 'freepromotion'"
        >
          <b-col class="p-0">
            <ChatOrderFreeProductItem :freeproduct="freeitem" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import ChatOrderFreeProductItem from "@/components/chat/chatOrder/chatOrderList/ChatOrderFreeProductItem";
export default {
  name: "ChatOrderFreeProduct",
  props: {
    freeProducts: Array
  },
  components: {
    ChatOrderFreeProductItem
  }
};
</script>

<style lang="scss" scoped></style>
