<template>
  <div>
    <div class="d-flex justify-content-between px-3 pb-2">
      <div class="font-weight-bold d-flex">
        <div v-if="$route.query.isMobile == 'true'" class="pr-2">
          <span
            @click="$store.commit('UPDATE_SHOW_CLOSE_BUTTON_CHATORDER', false)"
            class="cursor-pointer my-auto"
          >
            <font-awesome-icon :icon="['fas', 'chevron-left']" class="" />
          </span>
        </div>
        <span class="mr-3">
          ข้อมูลลูกค้า
        </span>
        <!-- <div class="custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            id="customSwitch"
            v-model="isTranslate"
            @change="handleTranslate"
          />
          <label class="custom-control-label" for="customSwitch1">
            แปลภาษา</label
          >
        </div> -->
        <div>
          <!-- <b-form-checkbox v-model="isTranslate" name="check-button-isTranslate" switch> -->
          <b-form-checkbox
            v-model="$store.state.isChatTranslate"
            name="check-button-isTranslate"
            switch
            @change="handleTranslate"
          >
            แปลภาษา
          </b-form-checkbox>
        </div>
      </div>
      <div>
        <a
          v-if="isShowDetail"
          href="#"
          @click.prevent="$emit('handle-show-modal-user', true)"
          >ดูโปรไฟล์</a
        >
      </div>
      <!-- @click.prevent="$emit('handle-chage-page', 1)" -->
    </div>
    <div class="px-3">
      <div class="d-flex flex-row px-2 bg-light rounded-lg py-2">
        <div class="user-img d-flex">
          <div
            v-if="imgUrlCurrentChat"
            class="rounded-circle user_img_display"
            v-bind:style="{
              backgroundImage: 'url(' + imgUrlCurrentChat + ')'
            }"
          ></div>
          <font-awesome-icon v-else :icon="['fas', 'user']" class="my-auto" />
        </div>
        <div class="d-flex flex-column pl-3 w-100">
          <div :class="['d-flex']">
            <span>{{ nameCurrentChat }}</span>
          </div>
          <div
            :class="[
              'd-flex justify-content-between w-100 mt-1',
              { 'my-auto': !isShowDetail }
            ]"
          >
            <span>{{ userInfo.firstname }} {{ userInfo.lastname }}</span>
          </div>
          <div
            v-if="isShowDetail"
            class="d-flex justify-content-between w-100 mt-1"
          >
            <span>โทร: {{ userInfo.telephone || "-" }}</span>
          </div>
        </div>
      </div>
      <div class="p-2 bg-light rounded-lg mt-3">
        <div class="d-flex justify-content-between w-100 mt-1">
          <b>Tier: {{ userTier.name || "-" }}</b>
          <a href="#" @click.prevent="$emit('handle-show-modal-tier', userTier)"
            >แก้ไข</a
          >
        </div>
        <div
          v-for="(item, index) in userCustomField"
          :key="index + 'userCustomField'"
          class="d-flex justify-content-between w-100 mt-1"
        >
          <span>{{ item.name }}: {{ item.value || "-" }}</span>
          <a
            href="#"
            v-if="item.isEdited"
            @click.prevent="$emit('handle-show-modal-custom-field', item)"
            >แก้ไข</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatOrderProfileCard",
  props: {
    userInfo: {
      required: false,
      type: Object
    },
    userChatInfo: {
      required: false,
      type: Object
    },
    isShowDetail: {
      required: false,
      type: Boolean
    },
    isTranslate: {
      required: false,
      type: Boolean
    },
    userCustomField: {
      required: false,
      type: Array
    },
    userTier: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      imgUrlCurrentChat: "",
      nameCurrentChat: ""
    };
  },
  mounted() {
    this.imgUrlCurrentChat = this.$store.getters.getImgUrlCurrentChat;
    this.nameCurrentChat = this.$store.getters.getNameCurrentChat;
  },
  methods: {
    handleTranslate() {
      this.$store.dispatch("handleChangeChatTranslate", {
        userId: this.userChatInfo.id
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.user-img {
  width: 5rem;
  height: auto;
}
.user_img_display {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}

.user-img svg {
  width: 80%;
  height: 80%;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #832abf;
  background-color: #832abf;
}
::v-deep a:disabled {
  opacity: 0.5 !important;
  text-decoration: none !important;
}
@media (max-width: 767.98px) {
}
</style>
