<template>
  <div class="py-0 px-3">
    <label for="input-live-promoCode" class="mb-1">คูปองโค้ด</label>
    <b-input-group class="rounded">
      <b-form-input
        id="input-live-promoCode"
        v-model="order.promoCode"
        :state="nameState"
        aria-describedby="input-live-help-promoCode input-live-feedback-promoCode"
        placeholder="กรุณาใส่คูปองโค้ด"
        trim
        autocomplete="off"
        @keypress="handleKeyupSearch"
        :disabled="
          order.products.length == 0 || order.statusId > 1 || isUseCoupon
            ? true
            : false
        "
      ></b-form-input>
      <b-input-group-append v-if="order.statusId < 2">
        <b-button
          @click="onEnterInput"
          :disabled="!order.promoCode"
          v-if="!isUseCoupon"
          class="text-white bg-color-primary"
          >ใช้คูปอง</b-button
        >
        <b-button
          @click="onRemoveCoupon"
          class="text-white bg-color-primary"
          v-else
          >ลบคูปอง</b-button
        >
      </b-input-group-append>
      <b-form-invalid-feedback id="input-live-feedback-promoCode">
        {{ order.promoCodeMessage }}
      </b-form-invalid-feedback>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    isUseCoupon: {
      required: true,
      type: Boolean
    },
    order: {
      required: true,
      type: Object
    },
    onCalculatedPrice: {
      required: false,
      type: Function
    }
  },
  data() {
    return {
      nameState: null
    };
  },
  methods: {
    onEnterInput() {
      this.order.promoCodeMessage = null;
      if (this.order.promoCode) {
        this.onCalculatedPrice();
      }
    },
    handleKeyupSearch(e) {
      this.nameState = null;
      if (e.keyCode === 13) {
        this.onEnterInput();
      }
    },
    onRemoveCoupon() {
      this.order.promoCode = "";
      this.onCalculatedPrice();
    }
  },
  watch: {
    "order.promoCodeMessage": function() {
      this.nameState = null;
      if (this.order.promoCodeMessage) {
        this.nameState = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-add-coupon {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
  border-color: #832abf;
}
</style>
