<template>
  <div>
    <b-modal
      v-model="isModalShow"
      title="ข้อมูลลูกค้า"
      hide-footer
      scrollable
      @hide="hide"
    >
      <div>
        <b-row>
          <b-col>
            <ChatOrderProfileDetailForm
              :userInfo="orderSelected.buyerInfo.userInfo"
              :getUserInfo="getUserInfo"
            />
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="d-flex justify-content-between">
            <div class="mb-2">รายการที่อยู่</div>
            <div class="mb-2">
              <b-button
                v-if="!addressOpenEdit.includes(9999)"
                @click="toggleEditAddress(9999, 9999, {})"
                class="bg-color-primary text-white"
                size="sm"
              >
                <font-awesome-icon icon="plus" /> เพิ่มที่อยู่</b-button
              >
            </div>
          </b-col>
        </b-row>

        <div v-if="addressOpenEdit.includes(9999)">
          <ChatOrderProfileModalFormAddress
            typeForm="Add"
            :form="formEditAddrss"
            :v="$v.formEditAddrss"
            :toggleEditAddress="toggleEditAddress"
            :submitAddress="submitAddress"
          />
        </div>
        <div v-else>
          <div v-if="addressList.length">
            <b-card v-for="(addr, indx) in addressList" :key="indx">
              <div v-if="!addressOpenEdit.includes(indx)" class="d-flex">
                <div class="">
                  <div>{{ addr.address }}</div>
                  <div>{{ addr.road }} {{ addr.subdistrict }}</div>
                  <div>{{ addr.district }} {{ addr.province }}</div>
                  <div>{{ addr.zipcode }}</div>
                </div>
                <div class="ml-auto d-flex flex-column">
                  <div
                    @click="toggleEditAddress(1, indx, addr)"
                    class="p-0 mt-auto mb-1 cursor-pointer"
                  >
                    <font-awesome-icon icon="edit" />
                    แก้ไข
                  </div>
                  <div
                    @click="submitDeleteAddress(addr.id)"
                    class="p-0 mb-auto mt-1 cursor-pointer"
                  >
                    <font-awesome-icon icon="trash" />
                    ลบ
                  </div>
                </div>
              </div>
              <div v-if="addressOpenEdit.includes(indx)">
                <ChatOrderProfileModalFormAddress
                  typeForm="Edit"
                  :form="formEditAddrss"
                  :v="$v.formEditAddrss"
                  :toggleEditAddress="toggleEditAddress"
                  :submitAddress="submitAddress"
                />
              </div>
            </b-card>
          </div>
          <div v-else class="text-center py-3">
            ไม่มีรายการที่อยู่
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, numeric, minValue } from "vuelidate/lib/validators";
import ChatOrderProfileModalFormAddress from "@/components/chat/chatOrder/chatOrderProfile/chatOrderProfileAddress/ChatOrderProfileModalFormAddress";
import ChatOrderProfileDetailForm from "@/components/chat/chatOrder/chatOrderProfile/chatOrderProfileAddress/ChatOrderProfileDetailForm";
export default {
  name: "ModalAddressDetail",
  components: {
    ChatOrderProfileModalFormAddress,
    ChatOrderProfileDetailForm
  },
  props: {
    orderSelected: Object,
    userGUID: {
      required: false,
      type: String
    },
    getUserInfo: {
      required: false,
      type: Function
    }
  },
  data() {
    return {
      // userInfo: this.orderSelected.buyerInfo.userInfo,
      data: this.orderSelected,
      isModalShow: false,
      addressList: [],
      formEditAddrss: {
        id: 0,
        firstname: null,
        lastname: null,
        address: null,
        road: null,
        province: null,
        district: null,
        subdistrict: null,
        zipcode: null
      },
      addressOpenEdit: [],
      isLoadingSaveTag: false
    };
  },
  methods: {
    show() {
      this.data = this.orderSelected;

      this.isModalShow = true;
      // this.getAddressList();
      this.addressList = this.$store.getters.getChatUserAddressList;
      console.log(this.orderSelected);
    },
    hide() {
      this.isModalShow = false;
    },
    handleBillingAddress() {},
    toggleEditAddress(flag, idx, addressVal) {
      this.addressOpenEdit = [];
      this.formEditAddrss = {
        id: 0,
        firstname: null,
        lastname: null,
        address: null,
        road: null,
        province: null,
        district: null,
        subdistrict: null,
        zipcode: null
      };
      if (flag == 1) {
        this.formEditAddrss = addressVal;
        this.addressOpenEdit.push(idx);
      } else if (flag == 9999) {
        this.addressOpenEdit.push(9999);
      }
    },
    submitAddress(flag) {
      this.$v.formEditAddrss.$touch();
      if (this.$v.formEditAddrss.$error) {
        return;
      }
      this.$v.formEditAddrss.$reset();
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .post(
          `${this.$baseUrl}/api/user/${this.userGUID}/${
            flag == 1 ? "address" : "updateaddress"
          }`,
          this.formEditAddrss
        )
        .then(async response => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.result == 1) {
            this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            this.toggleEditAddress(2, 0, {});
            await this.$store.dispatch("handleGetChatAddressList");
            this.addressList = this.$store.getters.getChatUserAddressList;
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    },

    submitDeleteAddress(id) {
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .delete(`${this.$baseUrl}/api/user/${this.userGUID}/address/${id}`)
        .then(async response => {
          this.$store.dispatch("setIsLoading", false);
          if (response.data.result == 1) {
            this.$store.commit("setMessageGlobalAlert", "สำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            await this.$store.dispatch("handleGetChatAddressList");
            this.addressList = this.$store.getters.getChatUserAddressList;
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              response.data.detail
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    }
  },
  validations() {
    return {
      formEditAddrss: {
        address: { required },
        district: {
          required
        },
        subdistrict: {
          required
        },
        province: {
          required
        },
        zipcode: {
          required,
          numeric,
          minValue: minValue(5)
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.name-title {
  border: none;
  border-bottom: 2px solid #ebebeb;
  outline: none;
  box-shadow: none;
}

.btn-cancel-edit {
  color: #832abf !important;
  background-color: #ffffff !important;
  border: 1px solid #9450c2;
}
</style>
