<template>
  <div class="border bg-white">
    <div class="px-3 py-2">
      <span class="font-weight-bold">Note</span>
    </div>
    <div>
      <b-form-textarea
        id="textarea-chatbox-note"
        v-model="note"
        placeholder="กรอกบันทึก"
        rows="4"
      ></b-form-textarea>
    </div>
    <div class="d-flex justify-content-end py-2 px-3">
      <b-button
        variant="outline-primary"
        size="sm"
        @click="$emit('handle-show-note', false)"
        >ยกเลิก</b-button
      >
      <b-button
        variant="primary"
        size="sm"
        class="ml-2"
        @click="saveNote"
        :disabled="
          !note || (note && note.trim() == '') || $store.state.isLoading
        "
        >บันทึก</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatBoxNote",
  components: {},
  props: {
    userGUID: {
      required: false,
      type: String
    },
    chatroomId: {
      required: false,
      type: String
    },
    handleLoadError: {
      required: false,
      type: Function
    }
  },
  methods: {
    getNote() {
      fetch(`${this.$chatBaseUrl}/note/${this.chatroomId}`, {
        method: "GET",
        headers: new Headers(this.$headersChat)
      })
        .then(response => response.json())
        .then(async result => {
          if (result.result == 1) {
            this.note = result.detail.data;
          }
        })
        .catch(error => {
          this.handleLoadError();
          console.log("error", error);
        });
    },
    saveNote() {
      this.$store.dispatch("setIsLoading", true);
      let body = {
        userGUID: this.userGUID,
        chatroomId: this.chatroomId,
        data: this.note
      };
      fetch(`${this.$chatBaseUrl}/note/save`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(async result => {
          this.$store.dispatch("setIsLoading", false);
          if (result.result == 1) {
            this.$store.commit("setMessageGlobalAlert", "บันทึกสำเร็จ");
            this.$store.commit("setShowGlobalAlert", true);
            this.$emit("handle-show-note", false);
          } else {
            this.handleLoadError();
            this.$store.commit(
              "setMessageGlobalAlertError",
              result.result.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.handleLoadError();
          console.log("error", error);
          this.$store.dispatch("setIsLoading", false);
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
        });
    }
  },
  data() {
    return {
      note: ""
    };
  },
  created() {
    this.getNote();
  }
};
</script>

<style lang="scss" scoped>
.btn-primary {
  background-color: #832abf !important;
  border-color: #832abf;
  color: #fff;
}
.btn-outline-primary {
  border: 1px solid #9450c2;
  color: #832abf;
}
.btn-outline-primary:active,
.btn-outline-primary:hover {
  border: 1px solid #9450c2;
  color: #832abf;
  background-color: rgb(240, 240, 240) !important;
}
@media (max-width: 767.98px) {
}
</style>
