<template>
  <div class="d-flex">
    <div class="d-flex flex-column flex-grow-1">
      <span class="">
        <span class="text-dark">
          Order No:
        </span>
        <span class="font-weight-bold">{{ order.invoiceNo }}</span>
      </span>
      <span class="d-flex my-auto text-dark">
        <img
          v-if="order.socialSourceId"
          :src="
            $store.getters.getChatSocialList[
              $store.getters.getChatSocialList.findIndex(
                t => t.id == order.socialSourceId
              )
            ].imageUrl
          "
          alt=""
          width="16"
          class="my-auto"
        />
        <span class="my-auto ml-1 text-size-12">
          {{ order.socialSourceName }}
        </span>
      </span>
      <span class="font-weight-bold my-auto"
        >{{ order.grandTotal | numeral("0,0.00") }} THB</span
      >
    </div>
    <div class="d-flex flex-column">
      <div class="mb-auto text-right">
        <span
          class="badge badge-pill font-weight-normal py-1 px-2"
          :style="styleCustom(order.statusId)"
          >{{ order.status }}</span
        >
      </div>
      <span class="pt-1 my-auto text-size-12 text-right">
        <ChatDateTime :date="order.updatedTime" isFullTime />
      </span>
      <div class="text-right">
        <b-button
          variant="link"
          @click="$emit('set-transactionId', order.id)"
          class="pr-0 text-dark font-weight-bold"
          ><u>ดูรายละเอียด</u></b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ChatDateTime from "@/components/chat/ChatDateTime";

export default {
  name: "ChatOrderListCard",
  components: { ChatDateTime },
  props: {
    order: {
      required: false,
      type: Object
    }
  },
  methods: {
    styleCustom(stsId) {
      if (stsId == 5) {
        return {
          backgroundColor: "#d4edda",
          color: "#155724"
        };
      } else if ([6, 7, 8, 12].includes(stsId)) {
        return {
          backgroundColor: "#f8d7da",
          color: "#721c24"
        };
      } else if ([1].includes(stsId)) {
        return {
          backgroundColor: "#e2e3e5",
          color: "#383d41"
        };
      } else if ([4, 14, 15].includes(stsId)) {
        return {
          backgroundColor: "rgb(113 96 196 / 27%)",
          color: "#7160C4"
        };
      } else {
        return {
          backgroundColor: "#fff3cd",
          color: "#856404"
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text-size-12 {
  font-size: 12px;
}
.text-size-14 {
  font-size: 14px;
}
.text-size-16 {
  font-size: 16px;
}
@media (max-width: 767.98px) {
}
</style>
