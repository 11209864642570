<template>
  <div>
    <div class="img_upload" v-if="content.custom.imageUrl">
      <div
        class="img_upload-display"
        v-bind:style="{
          backgroundImage: 'url(' + content.custom.imageUrl + ')'
        }"
      ></div>
    </div>

    <!-- <span class="font-weight-bold">{{ exJson.Message.Text }}</span
    ><br /> -->
    <!-- <span class="font-weight-bold">{{ exJson.Message.Custom.Text }}</span> -->
    <!-- <br /> -->
    <div v-if="content.custom">
      <hr v-if="content.messageType == 3" />

      <span>{{ content.custom.text }}</span>
      <!-- v-html="con.message" -->
      <hr
        v-if="
          content.custom.button &&
            content.custom.imageUrl &&
            content.custom.text
        "
      />
      <button
        v-for="(btn, j) in content.custom.button"
        type="button"
        class="btn btn-light w-100 mb-1 font-btn"
        :key="j"
      >
        <span class="font-btn">{{ btn.label }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    con: {
      required: false,
      type: Object
    }
  },
  created() {
    this.content = this.con;
  },
  data() {
    return {
      // content type 3
      // content: {
      //   custom: [
      //     { name: "ReplyType", value: 1 },
      //     { name: "Text", value: "เทส TextImage" },
      //     {
      //       name: "ImageUrl",
      //       value:
      //         "https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300"
      //     },
      //     { name: "LanguagedId", value: 1 }
      //   ]
      // },
      // content type 5
      // content: {
      //   custom: [
      //     { name: "ReplyType", value: 1 },
      //     { name: "Text", value: "เทส Flex" },
      //     {
      //       name: "ImageUrl",
      //       value:
      //         "https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300"
      //     },
      //     {
      //       name: "Button",
      //       value: [
      //         [
      //           { name: "type", value: 1 },
      //           { name: "Action", value: 3 },
      //           { name: "Label", value: "บริการ" },
      //           { name: "Data", value: "บริการ" }
      //         ]
      //       ]
      //     }
      //   ]
      // },
      content: {},
      exJson: {
        Mode: 2,
        TimeStamp: 1638849618599,
        ReplyToken: "6fad3fcec1d54b7a9512ba7a15be2ddb",
        Message: {
          MessageType: 2,
          LanguageId: 1,
          MessageId: "520211207110018599216062",
          Text:
            "ยินดีต้อนรับสู่ ERAWON เลือกเมนูที่ท่านต้องการได้เลย\n -ดูสินค้า\n -ช่วยเหลือ",
          Custom: {
            ReplyType: 2,
            Text: "ยินดีต้อนรับสู่ ERAWON เลือกเมนูที่ท่านต้องการได้เลย",
            Button: [
              {
                Type: 1,
                Action: 3,
                Label: "ดูสินค้า",
                Data: "หมวดหมู่ทั้งหมด"
              },
              { Type: 1, Action: 3, Label: "ช่วยเหลือ", Data: "ช่วยเหลือ" }
            ],
            LanguageId: 1
          }
        },
        Source: {
          Sender: 2,
          UserGUID: "9b19bc1c-d477-4437-96bb-af85ca8bc5e2",
          ChannelId: "1656634151",
          BrandId: 50
        },
        PrechatInfo: null
      }
    };
  }
};
</script>

<style lang="scss" scoped>
div .img_upload {
  margin-left: auto;
  height: auto;
  max-height: 20rem;
  width: 100%;
  max-width: 30rem;
}
div .img_upload .img_upload-display {
  width: 100%;
  padding-bottom: 56.25%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: inherit !important;
}
.font-btn {
  color: #9a161f;
  font-weight: bold;
}
</style>
