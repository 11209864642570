<template>
  <div class="py-0 px-3">
    <div class="d-flex">
      <span class="my-auto">รายละเอียดเพิ่มเติม</span>
      <b-button
        v-if="!isEdit && isAction"
        variant="link"
        class="pr-0 ml-auto text-dark"
        @click="handleEdit(true)"
        >แก้ไข</b-button
      >
    </div>
    <div class=" p-3 bg-color-secondary rounded shadow-sm  text-color-black">
      <div v-if="!isEdit" class="d-flex">
        <p>{{ order.note || " " }}</p>
      </div>
      <div v-if="isEdit" class="d-flex">
        <b-form-textarea
          id="textarea-note"
          v-model="order.note"
          placeholder="กรอกรายละเอียดเพิ่มเติม"
          rows="4"
        ></b-form-textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      required: true,
      type: Object
    },
    isAction: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      isEdit: false
    };
  },
  created: async function() {},

  methods: {
    handleEdit(flag) {
      if (flag) {
        this.isEdit = flag;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
