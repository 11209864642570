var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalAlert',{ref:"GlobalModalAlert",attrs:{"text":_vm.$store.state.messageGlobalAlert,"textSecondLine":_vm.$store.state.messageGlobalAlertSecondLine},on:{"hide":function () {
        _vm.$store.commit('setShowGlobalAlert', false);
        _vm.$store.commit('setMessageGlobalAlert', '');
        _vm.$store.commit('setMessageGlobalAlertSecondLine', '');
      }}}),_c('ModalAlertError',{ref:"GlobalModalAlertError",attrs:{"text":_vm.$store.state.messageGlobalAlertError,"textSecondLine":_vm.$store.state.messageGlobalAlertErrorSecondLine},on:{"hide":function () {
        _vm.$store.commit('setShowGlobalAlertError', false);
        _vm.$store.commit('setMessageGlobalAlertError', '');
        _vm.$store.commit('setMessageGlobalAlertErrorSecondLine', '');
      }}}),_c('ModalAlertWarning',{ref:"GlobalModalAlertWarning",attrs:{"text":_vm.$store.state.messageGlobalAlertWarning,"textSecondLine":_vm.$store.state.messageGlobalAlertWarningSecondLine},on:{"hide":function () {
        _vm.$store.commit('setShowGlobalAlertWarning', false);
        _vm.$store.commit('setMessageGlobalAlertWarning', '');
        _vm.$store.commit('setMessageGlobalAlertWarningSecondLine', '');
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }